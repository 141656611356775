import React, { useState } from 'react';
import parse from 'html-react-parser/dist/html-react-parser';
import { motion, LayoutGroup } from 'framer-motion';
import ContentCta from './ContentCta';

export default function ContentCard({ image, title, description, cta }) {
    const [isOpen, setIsOpen] = useState(false);
	const [delayHandler, setDelayHandler] = useState(null)

	const handleMouseEnter = (event) => {
		setDelayHandler(setTimeout(() => {
            setIsOpen(true);
        }, 200))
    }

    const handleMouseLeave = (item) => {
		setIsOpen(false);
        clearTimeout(delayHandler)
    }

	return (
        <LayoutGroup>
            <div
                className='relative group overflow-hidden min-h-[420]'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div
                    style={{ '--image-url': `url(${image})` }}
                    className='bg-[image:var(--image-url)]
						bg-no-repeat bg-[cover] h-full flex flex-col justify-end'
                >
                    <motion.div
                        layout
                        transition={{
                            type: 'easeIn',
                            duration: 0.2,
                        }}
                        className='bg-background/80 px-5 py-8'
                    >
                        {title && (
                            <h3
                                className={`font-sans text-2xl mb-2 leading-7 ${isOpen ? 'text-accent' : 'text-white'}`}
                            >
                                {title}
                            </h3>
                        )}
                        <motion.div layout className={`
                            dark duration-200 delay-[400ms] ease-in transition-opacity
                            ${isOpen ? ' opacity-100 ' : 'opacity-0 '}
                        `}>
                            {isOpen
                                ? description && (
                                      <div className='text-lg prose text-white dark:prose-invert'>
                                          {parse(description)}
                                      </div>
                                  )
                                : null}
                            {isOpen && cta.has_cta ? (
                                <ContentCta {...cta} />
                            ) : null}
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </LayoutGroup>
    );
}
